import {
  type ComponentStyleConfig,
  theme as originalTheme,
} from '@chakra-ui/theme';

export const Accordion: ComponentStyleConfig = {
  parts: ['button', 'panel', 'container'],
  baseStyle: {
    button: {
      color: 'grey.900',
      fontSize: 'lg',
      fontWeight: 'bold',
      lineHeight: '1.5rem',
      letterSpacing: 'extraWide',
      _hover: {
        color: 'blue.500',
        background: 'none',
        fill: 'blue.500',
      },
    },
    panel: {
      pt: '4',
      pb: '0',
    },
    container: {
      border: 'none',
      p: '6',
    },
  },
  variants: {
    republic: {
      button: {
        ...originalTheme.components.Accordion?.baseStyle?.button,
        fontWeight: 'normal',
        color: 'inherit',
        lineHeight: 'base',
      },
      panel: {
        ...originalTheme.components.Accordion?.baseStyle?.panel,
      },
      container: {
        ...originalTheme.components.Accordion?.baseStyle?.container,
        p: 0,
      },
    },
  },
};
