/* eslint-disable @nx/enforce-module-boundaries */
import { cssVar } from '@chakra-ui/react';
import type { ComponentStyleConfig } from '@chakra-ui/theme';
const $arrowBg = cssVar('popper-arrow-bg');

export const Tooltip: ComponentStyleConfig = {
  baseStyle: {
    background: 'grey.900',
    color: 'white',
    px: '3',
    py: '2.5',
    borderRadius: 'md',
    [$arrowBg.variable]: '#222222',
  },
  variants: {
    republic: {},
  },
};
